@import "support";

.loginBlk {
  display: block;
  width: 750px;
  height: 400px;
  margin: 80px auto 0px auto;
  background: $color-white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);

  ul.loginContentBlk {
    display: block;
    height: 100%;

    li.loginLeft,
    li.loginRight {
      display: inline-block;
      vertical-align: top;
    }

    li.loginLeft {
      width: 350px;
      height: 100%;
      background: $color-blue-dark;
      background-image: $bgTexure;
    }

    li.loginRight {
      width: calc(100% - 350px);
      width: -webkit-calc(100% - 350px);
      padding: 0px 25px;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.loginLeft {
        display: none;
      }

      li.loginRight {
        display: block;
        width: 100%;
      }
    }

    @media ($smaller-than: $breakpoint-499) {
      li.loginRight {
        padding: 0px 15px;
      }
    }

    ul.loginLeftHorBlk {
      display: block;
      height: 100%;

      li.loginLeftHorBlk {
        display: block;
        height: calc(100% - 257px);
        height: -webkit-calc(100% - 257px);
      }

      li.loginLeftHorBlk:first-child {
        height: 142px;
        background: $login_top_SVG;
        background-size: 350px;
      }

      li.loginLeftHorBlk:last-child {
        height: 115px;
        background: $login_bottom_SVG;
        background-size: 350px;
      }

      .loginHeadText {
        display: block;
        font-family: $font-arial;
        font-size: $fontsize-26;
        color: $color-white;
        text-align: center;
        padding-top: 15px;
      }

      .loginDivider {
        width: 40px;
        margin: 15px auto;
        height: 5px;
        border-radius: 10px;
        background: $color-white;
      }

      .loginSupportText {
        display: block;
        font-family: $font-arial;
        font-size: $fontsize-13;
        color: $color-offblack-light;
        text-align: center;
        line-height: $fontsize-24;
        padding: 0px 20px;
      }
    }
  }

  .mcplLoginLogo {
    display: block;
    height: 75px;
    margin: 20px 0px 30px 0px;
  }

  .loginFormQ {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    font-weight: bold;
    color: $color-blackbrown;
    margin: 20px 0px 5px 0px;
  }

  .loginFormTxtBox input[type="email"],
  .loginFormTxtBox input[type="password"] {
    border-color: $color-blue-lighter;
    background-image: $bgTexure;
    border-radius: 5px;
  }

  .button_loginForm {
    display: block;
    width: 100%;
    padding: 11px 0px;
    border: 0px;
    border-radius: 6px;
    font-family: $font-arial;
    font-size: $fontsize-17;
    font-weight: bold;
    color: $color-white;
    outline: none;
    background: $color-red-dark;
    background-image: $bgTexure;
    text-align: center;
    margin-top: 15px;
  }

  .button_loginForm:hover {
    background: $color-red;
    cursor: pointer;
  }

  .button_loginForm[disabled],
  .button_loginForm[disabled]:hover,
  .button_loginForm.disabled,
  .button_loginForm.disabled:hover {
    background: $color-lightblack;
    color: $color-offblack;
    cursor: default;
    text-shadow: none;
    position: static;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0px dotted $color-disable;
  }

  @media ($smaller-than: $breakpoint-699) {
    .button_loginForm {
      margin-top: 10px;
      padding: 10px 0px;
    }
  }
}
.powered {
  display: block;
  text-align: center;
  font-family: $font-arial;
  font-size: $fontsize-13;
  color: $color-offblack;
  margin-top: 5px;

  a {
    color: $color-offblack;
  }

  a:hover {
    color: $color-red;
  }
}

@media ($smaller-than: $breakpoint-799) {
  .loginBlk {
    width: 97%;
    margin: 20px auto;
    height: auto;
    padding: 15px 0px 25px 0px;
  }
}

@media ($smaller-than: $breakpoint-499) {
  .loginBlk {
    width: 95%;
    padding: 5px 0px 25px 0px;
  }
}
