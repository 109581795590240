@import "support";


.popup_dialog {
    overflow: hidden;
    z-index: 2500;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
    /* background: $color-white; */
    border-radius: 10px;
}

.pop_head,
.pop_head_bgRed {
    display: block;
    font-family: $font-arialBlack;
    font-size: $fontsize-22;
    color: $color-white;
    padding: 13px 0;
    background: $color-blue-navi-dark;
    background-image: $bgTexure;
    text-align: center;
    //border-radius: 8px 8px 0 0;
}

.pop_head_bgRed {
    background: $color-red;
    background-image: $bgTexure;
}

.pop_contentArea {
    display: block;
    padding: 15px 20px 10px 20px;
    max-height: 250px;
    overflow-y: auto;
    background: $color-white;
}

.pop_contentHead,
.pop_content {
    display: block;
    font-family: $font-arialBlack;
    font-size: $fontsize-16;
    color: $color-blackbrown;
}

.pop_content {
    font-family: $font-arialBlack;
    font-size: $fontsize-15;
    color: $color-blackbrown-light;
}

@media ($smaller-than: $breakpoint-599) {
    .pop_head {
        font-size: $fontsize-20;
        padding: 10px 0;
    }

    .pop_contentArea {
        padding: 10px;
    }

    .pop_contentHead {
        font-size: $fontsize-15;
        text-align: center;
    }

    .pop_content {
        font-size: $fontsize-14;
        text-align: center;
    }

    .popConsent {
        font-size: $fontsize-12;
    }
}

.pop_buttonLabel,
.pop_buttonLabel_borderTop_Red {
    display: block;
    text-align: center;
    padding: 8px 0;
    margin-bottom: 5px;
    border-top: 1px solid $color-offblack;
    background: $color-white;

    .pop_button {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 20px;
        height: 32px;
        margin-right: 5px;
        border: 0px solid $color-blue;
        border-radius: 5px;
        font-family: $font-arial;
        font-size: $fontsize-15;
        color: $color-white;
        outline: none;
        background: $color-bluedark;
        text-align: center;
    }

    .pop_button:hover {
        background: $color-blue-dark;
        cursor: pointer;
    }

    .pop_button[disabled],
    .pop_button[disabled]:hover,
    .pop_button.disabled,
    .pop_button.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
    }
}

.pop_statusLabel {
    display: block;
    text-align: center;
    padding: 8px 8px;
    border-top: 1px solid $color-offblack;
    background: $color-white;
}

ul.contentIIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.contentIIcol {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 8px);
        width: -webkit-calc(100% / 2 - 8px);
        margin: 0px 15px 20px 0px;
    }

    li.contentIIcol:nth-child(even) {
        margin-right: 0px;
    }

    .formControlHead {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-16;
        color: $color-lightblack-dark;
        margin-bottom: 2px;
    }

    .formControlTxtField>input[type="text"],
    .formControlTxtField>input[type="email"] {
        font-size: $fontsize-16;
        border: 1px solid $color-offwhite-dark;
        background-image: $bgTexure;
        width: 100%;
        padding: 0px 10px 0px 10px;
        height: 34px;
        border-radius: 0px;
    }

    .formControlSelectField>select {
        padding: 0px 15px;
        height: 34px;
        color: $color-lightblack;
        background: $selectarrow_blackIcon_SVG $color-offwhitelight;
        background-position: right 3% center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid #d3d3d3;
    }

    .formControlSelectField>select:hover {
        cursor: pointer;
        background: $selectarrow_redIcon_SVG $color-offwhitelight;
        background-position: right 3% center;
    }


    @media ($smaller-than: $breakpoint-599) {
        li.contentIIcol {
            display: block;
            width: 100%;
            margin-right: 0px;
        }
    }
}