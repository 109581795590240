@import "support";

.footerBlk {
  clear: both;
  width: 100%;
  position: relative;
  z-index: 10;
  height: 35px;
  margin-top: -35px;
  background: $color-white;
  border-top: 2px solid $color-offblack-light;

  ul.footerLogin {
    display: block;

    li.footerLogin {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2);
      width: -webkit-calc(100% / 2);
      font-family: $font-arial;
      font-size: $fontsize-12;
      color: $color-blackbrown-light;
      line-height: 30px;
      padding-left: 10px;
    }

    li.footerLogin:last-child {
      text-align: right;
      padding-right: 10px;
    }

    li.footerLogin a {
      color: $color-blackbrown-light;
    }

    li.footerLogin a:hover {
      text-decoration: none;
      color: $color-blue-dark;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.footerLogin {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0px;
      }

      li.footerLogin:last-child {
        display: none;
      }
    }
  }

  .footerInpage {
    display: block;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-family: $font-arial;
    font-size: $fontsize-13;
    color: $color-white;
    background: $color-blue-dark;
    background-image: $bgTexure;

    a {
      text-decoration: none;
      color: $color-white;
    }

    a:hover {
      color: $color-offwhitelight;
    }
  }
}
